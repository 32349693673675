import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'index',
  component: () => import('../views/index.vue')
},
{
  path: '/erji',
  name: 'erji',
  component: () => import('../views/erji/index.vue')
},
{
  //年级信息
  path: '/erji/GradeInfo',
  name: 'GradeInfo',
  component: () => import('../views/erji/GradeInfo.vue')
},
{
  //运动器材损耗
  path: '/erji/equipmentSh',
  name: 'equipmentSh',
  component: () => import('../views/erji/equipmentSh.vue')
},
{
  //bmi成绩
  path: '/erji/bmiCj',
  name: 'bmiCj',
  component: () => import('../views/erji/bmiCj.vue')
},
{
  //项目信息
  path: '/erji/projectInfo',
  name: 'projectInfo',
  component: () => import('../views/erji/projectInfo.vue')
},
{
  //项目用时
  path: '/erji/projectTime',
  name: 'projectTime',
  component: () => import('../views/erji/projectTime.vue')
},
{
   //历年成绩
  path: '/erji/ProjectCj',
  name: 'ProjectCj',
  component: () => import('../views/erji/ProjectCj.vue')
},
{
  //职工电竞个人赛
  path: '/esports/projectTime',
  name: 'projectTime',
  component: () => import('../views/esports/projectTime.vue')
}]
const router = new VueRouter({
  routes
})

export default router